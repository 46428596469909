import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./TopEvents.css";

function TopServices() {
  const [services, setServices] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://balonim2001.com/php_files/fetch_main_categories.php"
        );
        const data = await response.json();
        if (data.success) {
          setServices(data.data);
        } else {
          console.error("Failed to fetch services:", data.message);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  return (
    <section id="top-services">
      <h2>אירועים מובילים</h2>
      <div className={`services-container ${hoveredId !== null ? "blur" : ""}`}>
        {loading ? (
          <p>טוען...</p>
        ) : (
          services.map((service) => (
            <Link
              to={
                service.type === "business"
                  ? `/אירועים-עסקיים/${encodeURIComponent(service.name)}`
                  : `/אירועים-פרטיים/${encodeURIComponent(service.name)}`
              }
              className={`service-card ${
                hoveredId === service.id ? "hovered" : ""
              }`}
              onMouseEnter={() => setHoveredId(service.id)}
              onMouseLeave={() => setHoveredId(null)}
              style={{ backgroundImage: `url(${service.icon})` }}
            >
              <div className="service-info">
                <h3>{service.name}</h3>
              </div>
            </Link>
          ))
        )}
      </div>
    </section>
  );
}

export default TopServices;
