import React from "react";
import icon from "./images/whatsApp_icon.png"; // Make sure the path is correct
import "./WhatsappIcon.css";
function WhatsappIcon() {
  return (
    <div id="whatsapp-icon-container">
      <img src={icon} alt="WhatsApp" />
    </div>
  );
}

export default WhatsappIcon;
