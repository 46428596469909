import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./EventsPage.css"; // Ensure you import the CSS

function EventsPage() {
  const { eventType } = useParams();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const translatedEventType =
    eventType === "אירועים-פרטיים" ? "private" : "business";

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://balonim2001.com/php_files/fetch_events.php?type=${translatedEventType}`
        );
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          setEvents(data.data);
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [translatedEventType]);

  if (loading) return <div className="events-page-loading">Loading...</div>;
  if (!events.length)
    return (
      <div className="events-page-no-events">
        No events found for this type.
      </div>
    );

  return (
    <div className="events-page-container">
      <div style={{ height: "100px" }}></div>
      <h1 className="events-page-title">
        {translatedEventType === "private"
          ? "אירועים פרטיים"
          : "אירועים עסקיים"}
      </h1>
      <div className="events-page-grid">
        {events.map((event, index) => (
          <div key={index} className="events-page-card">
            <img
              src={event.icon}
              alt={event.name}
              className="events-page-image"
            />
            <h4 className="events-page-event-title">{event.name}</h4>
            <p className="events-page-description">{event.description}</p>
            <Link
              to={`/event/${encodeURIComponent(event.name)}`}
              className="events-page-link"
            >
              לדף האירוע
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EventsPage;
