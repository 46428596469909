import React, { useState, useEffect } from "react";
import "./GalleryPage.css";

function GalleryPage() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://balonim2001.com/php_files/fetch_gallery_images.php"
        );
        const data = await response.json();
        if (data.success) {
          setImages(data.images);
        } else {
          console.error("Failed to fetch images:", data.message);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return (
      <div id="loading-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div id="gallery-page-container">
      <h1 id="gallery-page-title">העבודות שלנו</h1>
      {images.length === 0 ? (
        <div id="no-images-message">
          <p>אין תמונות להצגה כרגע</p>
        </div>
      ) : (
        <div id="images-container">
          {images.map((image, index) => (
            <div key={index} className="image-wrapper">
              <img
                src={image.image_path}
                alt={image.title || "Gallery Image"}
                className="gallery-image"
              />
              {image.title && (
                <div className="image-title">
                  <p>{image.title}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GalleryPage;
