import React, { useState, useEffect } from "react";
import { Sling as Hamburger } from "hamburger-react";
import "./MobileHeader.css";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="mobile-header">
      <div className="header-content">
        <img src={logo} alt="Logo" className="logo" />
        <div className="menu-icon" onClick={toggleMenu}>
          <Hamburger toggled={isOpen} toggle={setIsOpen} color="#333" />
        </div>
      </div>
      <nav className={`mobile-nav ${isOpen ? "open" : ""}`}>
        <div className="nav-content">
          <Link to="/" className="nav-link" onClick={toggleMenu}>
            בית
          </Link>
          <Link to="/about" className="nav-link" onClick={toggleMenu}>
            אודות
          </Link>
          <Link
            to="/אירועים/אירועים-פרטיים"
            className="nav-link"
            onClick={toggleMenu}
          >
            אירועים פרטיים
          </Link>
          <Link
            to="/אירועים/אירועים-עסקיים"
            className="nav-link"
            onClick={toggleMenu}
          >
            אירועים עסקיים
          </Link>
          <Link to="/contact" className="nav-link" onClick={toggleMenu}>
            יצירת קשר
          </Link>
        </div>
      </nav>
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>
    </header>
  );
};

export default MobileHeader;
