import React, { useState, useEffect } from "react";
import "./CreateCategory.css";

function CreateCategory() {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "business",
    mainCategory: false,
    icon: null,
  });

  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({ ...prevState, [name]: checked }));
    } else if (type === "file" && name === "icon") {
      setFormData((prevState) => ({ ...prevState, icon: files[0] }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("description", formData.description);
    data.append("type", formData.type);
    data.append("mainCategory", formData.mainCategory ? "true" : "false");
    data.append("icon", formData.icon);

    try {
      const response = await fetch(
        "https://balonim2001.com/php_files/add_category.php",
        {
          method: "POST",
          body: data,
        }
      );

      const resultText = await response.text();
      console.log(resultText);
      alert(resultText);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while saving the category.");
    }
  };

  return (
    <div className="create-category-container">
      <h2 className="form-title">צור קטגוריה</h2>
      <form className="category-form" onSubmit={handleSubmit}>
        <label className="input-label">
          שם:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="text-input"
          />
        </label>
        <label className="input-label">
          תיאור:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="textarea-input"
          ></textarea>
        </label>
        <label className="input-label">
          סוג:
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="select-input"
          >
            <option value="business">אירועים עסקיים</option>
            <option value="private">אירועים פרטיים</option>
            <option value="other">אחר</option>
          </select>
        </label>
        <label className="input-label checkbox-label">
          קטגוריה ראשית:
          <input
            type="checkbox"
            name="mainCategory"
            checked={formData.mainCategory}
            onChange={() =>
              setFormData((prevState) => ({
                ...prevState,
                mainCategory: !prevState.mainCategory,
              }))
            }
            className="checkbox-input"
          />
        </label>
        <label className="input-label">
          תמונה:
          <input
            type="file"
            name="icon"
            onChange={handleChange}
            className="file-input"
          />
        </label>
        <button type="submit" className="submit-button">
          יצירת קטגוריה
        </button>
      </form>
    </div>
  );
}

export default CreateCategory;
