import React, { useEffect, useRef, useState } from "react";
import banner from "./images/banner.png";
import "./Header.css";
import { Link } from "react-router-dom";

function Header() {
  const [businessEvents, setBusinessEvents] = useState([]);
  const [privateEvents, setPrivateEvents] = useState([]);
  const businessDropdownRef = useRef(null);
  const privateDropdownRef = useRef(null);
  const [dropdownTop, setDropdownTop] = useState("130px"); // State to hold the dynamic top value

  useEffect(() => {
    const updateDropdownPosition = () => {
      const scrollY = window.scrollY;
      const newTop = Math.max(0, 130 - scrollY); // Ensures that the top doesn't go below 0
      setDropdownTop(`${newTop}px`);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", updateDropdownPosition);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", updateDropdownPosition);
    };
  }, []);

  useEffect(() => {
    fetch("https://balonim2001.com/php_files/fetch_categories.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const business = data.data.filter((cat) => cat.type === "business");
          const privateCat = data.data.filter((cat) => cat.type === "private");
          setBusinessEvents(business);
          setPrivateEvents(privateCat);
        }
      });
  }, []);

  const handleMouseOver = (event) => {
    const li = event.currentTarget;
    const width = li.offsetWidth;
    li.style.height = `${width}px`;
  };

  const handleMouseOut = (event) => {
    const li = event.currentTarget;
    li.style.height = "100px"; // revert to the original height
  };

  useEffect(() => {
    const attachListeners = (dropdownRef) => {
      if (dropdownRef.current) {
        const listItems = dropdownRef.current.querySelectorAll("li");
        listItems.forEach((item) => {
          item.addEventListener("mouseover", handleMouseOver);
          item.addEventListener("mouseout", handleMouseOut);
        });
      }
    };

    attachListeners(businessDropdownRef);
    attachListeners(privateDropdownRef);

    return () => {
      const detachListeners = (dropdownRef) => {
        if (dropdownRef.current) {
          const listItems = dropdownRef.current.querySelectorAll("li");
          listItems.forEach((item) => {
            item.removeEventListener("mouseover", handleMouseOver);
            item.removeEventListener("mouseout", handleMouseOut);
          });
        }
      };

      detachListeners(businessDropdownRef);
      detachListeners(privateDropdownRef);
    };
  }, [businessEvents, privateEvents]); // Dependency array includes businessEvents and privateEvents

  return (
    <div id="header-container">
      <div id="header-logo-container">
        <Link to="/">
          <img src={banner} alt="banner" className="header-logo" />
        </Link>
      </div>
      <div id="header-links-container">
        <Link to="/" className="header-link">
          בית
        </Link>
        <Link to="/עלינו" className="header-link">
          אודות
        </Link>
        <div className="header-link mega-dropdown">
          אירועים עסקיים
          <ul
            className="dropdown-content"
            ref={businessDropdownRef}
            style={{ top: dropdownTop }}
          >
            {businessEvents.map((event, index) => (
              <li key={index}>
                <Link
                  to={`/אירועים-פרטיים/${encodeURIComponent(event.name)}`}
                  state={{ Event: event }}
                >
                  <p id="header-event-name">{event.name}</p>
                  <img
                    src={event.icon}
                    alt={event.name}
                    className="event-image"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="header-link mega-dropdown">
          אירועים פרטיים
          <ul
            className="dropdown-content"
            ref={privateDropdownRef}
            style={{ top: dropdownTop }}
            id="dropdown-content-private"
          >
            {privateEvents.map((event, index) => (
              <li key={index}>
                <Link
                  to={`/אירועים-פרטיים/${encodeURIComponent(event.name)}`}
                  state={{ Event: event }}
                >
                  <p id="header-event-name">{event.name}</p>
                  <img
                    src={event.icon}
                    alt={event.name}
                    className="event-image"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
