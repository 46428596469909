import React, { useState } from "react";
import "./ContactForm.css";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    try {
      const response = await fetch(
        "https://balonim2001.com/php_files/contact_form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      setSubmitMessage(data.message);
    } catch (error) {
      console.error("Error handling contact form:", error);
      setSubmitMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-card">
        <h2 className="contact-form-title">צרו איתנו קשר</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          {["name", "email", "phone"].map((field) => (
            <div className="input-container" key={field}>
              <input
                type={
                  field === "email"
                    ? "email"
                    : field === "phone"
                    ? "tel"
                    : "text"
                }
                id={field}
                name={field}
                required
                value={formData[field]}
                onChange={handleChange}
                placeholder=" "
              />
              <label htmlFor={field}>
                {field === "name"
                  ? "שם מלא"
                  : field === "email"
                  ? "אימייל"
                  : "מספר טלפון"}
              </label>
              <div className="input-underline"></div>
            </div>
          ))}
          <div className="input-container">
            <textarea
              id="comments"
              name="comments"
              required
              value={formData.comments}
              onChange={handleChange}
              placeholder=" "
            ></textarea>
            <label htmlFor="comments">הערות</label>
            <div className="input-underline"></div>
          </div>
          <button
            type="submit"
            className="submit-button-contact"
            disabled={isSubmitting}
          >
            {isSubmitting ? "שולח..." : "שלח"}
          </button>
        </form>
        {submitMessage && <div className="submit-message">{submitMessage}</div>}
      </div>
    </div>
  );
}

export default ContactForm;
