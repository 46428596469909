import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css"; // Importing the CSS file
import { Link } from "react-router-dom";

function Carousel({ eventType }) {
  const [events, setEvents] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1); // Start with the first item as active, centered in view
  const intervalRef = useRef(null); // useRef to hold the interval ID

  useEffect(() => {
    async function fetchEvents() {
      const response = await fetch(
        `https://balonim2001.com/php_files/fetch_events.php?type=${eventType}`
      );
      const data = await response.json();
      if (data.success) {
        setEvents(data.data);
        // Ensure there are always at least 3 events to display
        if (data.data.length < 3) {
          console.error(
            "Need at least 3 events to display the carousel properly."
          );
        }
      }
    }

    fetchEvents();
  }, [eventType]);

  useEffect(() => {
    startAutoRotate();
    return () => clearInterval(intervalRef.current); // Clear interval on component unmount
  }, [events]);

  const startAutoRotate = () => {
    intervalRef.current = setInterval(() => {
      next();
    }, 3000); // Change slides every 3 seconds
  };

  const next = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < events.length * 3 - 1 ? prevIndex + 1 : 0
    );
  };

  const prev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : events.length * 3 - 1
    );
  };

  const handleManualNavigation = (moveFunc) => {
    clearInterval(intervalRef.current);
    moveFunc();
    startAutoRotate();
  };

  const calculateOffset = () => {
    const screenWidth = window.innerWidth;
    const itemWidth = screenWidth < 768 ? 160 : 210; // Smaller item width for smaller screens
    return ((activeIndex - 1) % events.length) * itemWidth;
  };

  const allEvents = [...events, ...events, ...events];

  return (
    <div className="carousel-container">
      <h1>{eventType === "business" ? "אירועים עסקיים" : "אירועים פרטיים"}</h1>
      <div className="carousel-smaller-container">
        <div
          className="carousel-wrapper"
          style={{ transform: `translateX(${calculateOffset()}px)` }}
        >
          {allEvents.map((event, index) => (
            <Link
              to={`/event/${encodeURIComponent(event.name)}`}
              className={`carousel-item ${
                index % events.length === activeIndex % events.length
                  ? "active"
                  : ""
              }`}
            >
              <img src={event.icon} alt={event.name} />
              <span>{event.name}</span>
            </Link>
          ))}
        </div>
      </div>
      <div id="carousel-nav-container">
        <button
          className="nav-button prev"
          onClick={() => handleManualNavigation(prev)}
        >
          {"<"}
        </button>
        <button
          className="nav-button next"
          onClick={() => handleManualNavigation(next)}
        >
          {">"}
        </button>
      </div>
      <div className="events-navigation">
        <Link
          to={`/אירועים/${
            eventType === "business" ? "אירועים-עסקיים" : "אירועים-פרטיים"
          }`}
        >
          לדף {eventType === "business" ? "אירועים עסקיים" : "אירועים פרטיים"}
        </Link>
      </div>
    </div>
  );
}

export default Carousel;
