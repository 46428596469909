import React, { useState } from "react";
import CreateCategory from "../adminComponents/CreateCategory";
import GalleryManager from "../adminComponents/GalleryManager";
import ManageCategories from "../adminComponents/ManageCategories";
import ReviewManager from "../adminComponents/ReviewManager";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import "./Admin.css";

const AdminPage = () => {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [visibleComponent, setVisibleComponent] = useState(null);

  const handleLogout = () => {
    setIsAuthenticated(false);
    navigate("/login");
  };

  const handleBack = () => {
    setVisibleComponent(null);
  };

  return (
    <div id="admin-page-container">
      <h1>דף אדמין</h1>
      <button onClick={handleLogout}>יציאה ממשתמש</button>
      {visibleComponent === null ? (
        <div className="admin-menu">
          <button onClick={() => setVisibleComponent("createCategory")}>
            יצירת קטגוריה
          </button>
          <button onClick={() => setVisibleComponent("manageCategories")}>
            ניהול קטגוריות
          </button>
          <button onClick={() => setVisibleComponent("galleryManager")}>
            ניהול גלרייה
          </button>
          <button onClick={() => setVisibleComponent("reviewManager")}>
            ניהול ביקורות
          </button>
        </div>
      ) : (
        <div className="component-container">
          <button className="back-button" onClick={handleBack}>
            חזרה לתפריט הראשי
          </button>
          {visibleComponent === "createCategory" && <CreateCategory />}
          {visibleComponent === "manageCategories" && <ManageCategories />}
          {visibleComponent === "galleryManager" && <GalleryManager />}
          {visibleComponent === "reviewManager" && <ReviewManager />}
        </div>
      )}
    </div>
  );
};

export default AdminPage;
