import React from "react";
import aboutImage from "../images/about-image.jpg"; // Replace with your image path
import "./About.css";

function About() {
  return (
    <section id="about-section">
      <div className="about-container">
        <div className="about-image">
          <img src={aboutImage} alt="About Us" />
        </div>
        <div className="about-text">
          <h2>מי אנחנו?</h2>
          <p>
            אנחנו חברה שמתמחה במתן פתרונות בלונים מעוצבים לכל סוגי האירועים. כל
            אירוע עבורנו הוא משימה שאנו לוקחים על עצמנו באהבה ובמקצועיות, כדי
            להבטיח שהאירוע שלכם יהיה בלתי נשכח.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
