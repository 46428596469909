import React, { useState, useEffect } from "react";
import "./ReviewManager.css";

function ReviewManager() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetch("https://balonim2001.com/php_files/fetch_reviews.php?status=pending")
      .then((response) => response.json())
      .then((data) => setReviews(data.reviews))
      .catch((error) => console.error("Error fetching reviews:", error));
  }, []);

  const updateReviewStatus = async (id, status) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);

    await fetch("https://balonim2001.com/php_files/update_review_status.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        setReviews(reviews.filter((review) => review.id !== id));
      })
      .catch((error) => console.error("Error updating review status:", error));
  };

  return (
    <div>
      <h1>ניהול ביקורות</h1>
      {reviews.map((review) => (
        <div key={review.id}>
          <p>
            {review.customer_name}: {review.review_text}
          </p>
          <button onClick={() => updateReviewStatus(review.id, "approved")}>
            אשר
          </button>
          <button onClick={() => updateReviewStatus(review.id, "disapproved")}>
            בטל
          </button>
        </div>
      ))}
    </div>
  );
}

export default ReviewManager;
