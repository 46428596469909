import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Gallery.css";

function GallerySection() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          "https://balonim2001.com/php_files/fetch_gallery_images.php"
        );
        const data = await response.json();
        if (data.success && data.images.length > 0) {
          setImages(data.images.slice(0, 8)); // Fetch up to 8 images
        }
      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div id="gallery-section-container">
      <h1 id="gallery-section-title">העבודות שלנו</h1>
      <div id="images-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.image_path}
            alt={image.title || "Gallery Image"}
          />
        ))}
      </div>
      <Link to="/גלרייה" id="gallery-section-link">
        לגלרייה המלאה
      </Link>
    </div>
  );
}

export default GallerySection;
