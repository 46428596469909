import React, { useState, useEffect } from "react";
import "./GalleryManager.css";

function GalleryManager() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newImageTitle, setNewImageTitle] = useState(""); // State to store the new image title

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    const response = await fetch(
      "https://balonim2001.com/php_files/fetch_gallery_images.php"
    );
    const data = await response.json();
    if (data.success) {
      setImages(data.images);
    } else {
      alert(data.message);
    }
    setLoading(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);
    formData.append("title", newImageTitle); // Append the title to the formData

    const response = await fetch(
      "https://balonim2001.com/php_files/add_gallery_image.php",
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();
    alert(data.message);
    if (data.success) {
      fetchImages(); // Refresh the images list
      setNewImageTitle(""); // Clear the title input after upload
    }
  };

  const deleteImage = async (id) => {
    const response = await fetch(
      `https://balonim2001.com/php_files/delete_gallery_image.php?id=${id}`,
      {
        method: "DELETE",
      }
    );
    const data = await response.json();
    alert(data.message);
    if (data.success) {
      fetchImages(); // Refresh the images list
    }
  };

  return (
    <div className="gallery-manager">
      <h2>ניהול גלרייה</h2>
      <div className="upload-section">
        <input
          type="text"
          placeholder="כותרת תמונה"
          value={newImageTitle}
          onChange={(e) => setNewImageTitle(e.target.value)}
          className="input-field"
        />
        <input
          type="file"
          onChange={handleFileUpload}
          className="input-field"
        />
      </div>

      {loading ? (
        <p>טוען...</p>
      ) : (
        <div className="image-grid">
          {images.map((image) => (
            <div key={image.id} className="image-container">
              <img src={image.image_path} alt={image.title} />
              <div className="image-title">{image.title}</div>
              <button
                className="delete-button"
                onClick={() => deleteImage(image.id)}
              >
                מחיקה
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GalleryManager;
