import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import EventPage from "./pages/EventPage";
import EventsPage from "./pages/EventsPage"; // Import the EventsPage
import GalleryPage from "./pages/GalleryPage";
import WhatsappIcon from "./WhatsappIcon";
import TopHeader from "./topHeader";
import AdminPage from "./pages/Admin";
import AddReview from "./adminComponents/AddReview";
import Login from "./adminComponents/Login";
import { AuthProvider, useAuth } from "./AuthContext";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 965);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 965);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AuthProvider>
      <div className="App">
        {isMobile ? (
          <MobileHeader />
        ) : (
          <>
            <TopHeader />
            <Header />
          </>
        )}

        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/:category/:categoryName" element={<EventPage />} />
          <Route path="/אירועים/:eventType" element={<EventsPage />} />
          <Route path="/גלרייה" element={<GalleryPage />} />
          <Route
            path="/admin"
            element={<PrivateRoute component={AdminPage} />}
          />
          <Route path="/הוספת-ביקורת" element={<AddReview />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <WhatsappIcon />
      </div>
    </AuthProvider>
  );
};

const PrivateRoute = ({ component: Component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default App;
