import BalloonComponent from "../Baloons";
import ContactForm from "../HomeComponents/ContactForm";
import About from "../HomeComponents/About";
import ClientsSection from "../HomeComponents/Clients";
import GallerySection from "../HomeComponents/Gallery";
import Hero from "../HomeComponents/Hero";
import TopEvents from "../HomeComponents/TopEvents";
import Carousel from "../HomeComponents/Carousel";
import "./Home.css";
import ReviewSection from "../HomeComponents/ReviewSection";

function Home() {
  return (
    <div id="home-page-container">
      <Hero />
      <TopEvents />
      <About />
      <div id="home-carousels-container">
        <Carousel eventType="private" />
        <Carousel eventType="business" />
      </div>
      <GallerySection />
      <ContactForm />
      <BalloonComponent />
    </div>
  );
}

export default Home;
