import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

function BalloonComponent() {
  const containerRef = useRef(null);
  const balloonRef = useRef(null);
  const stringRef = useRef(null);

  useEffect(() => {
    // Realistic oscillating motion for the balloon
    const driftTl = gsap
      .timeline({ repeat: -1, yoyo: true })
      .to([balloonRef.current, stringRef.current], {
        x: "+=10", // Increase horizontal drift
        duration: 5, // Slightly longer duration for a smoother effect
        ease: "sine.inOut",
        onStart: function () {
          gsap.to(stringRef.current, {
            attr: { d: "M75,148 Q65,250 75,400" }, // Increase length and add curvature for leftward drift
            duration: 5,
            ease: "sine.inOut",
          });
        },
        onReverseComplete: function () {
          gsap.to(stringRef.current, {
            attr: { d: "M75,148 Q85,250 75,400" }, // Increase length and add curvature for rightward drift
            duration: 5,
            ease: "sine.inOut",
          });
        },
      })
      .to(
        [balloonRef.current, stringRef.current],
        {
          y: "-=20", // Increase vertical drift
          duration: 4, // Slightly shorter duration for a more noticeable effect
          ease: "sine.inOut",
        },
        "<"
      );

    // Enhanced horizontal movement for the container
    // Enhanced horizontal movement for the container
    const containerTl = gsap
      .timeline({ paused: true })
      .to(containerRef.current, {
        x: "-=20vw",
        y: "-=10vh",
        ease: "sine.inOut",
        duration: 2.5,
      })
      .to(containerRef.current, {
        x: "+=40vw",
        y: "+=20vh",
        ease: "sine.inOut",
        duration: 5,
      })
      .to(containerRef.current, {
        x: "-=20vw",
        y: "-=10vh",
        ease: "sine.inOut",
        duration: 2.5,
      })
      .to(containerRef.current, {
        x: "+=20vw", // Move it to the right side of the screen
        y: "+=20vh", // Adjust the final vertical position as needed
        ease: "power2.inOut",
        duration: 1,
      });

    // Animate the balloon and string
    const balloonTl = gsap
      .timeline({ paused: true, repeat: -1, yoyo: true })
      .to(balloonRef.current, { y: "-=0", ease: "sine.inOut", duration: 2.5 })
      .to(
        stringRef.current,
        {
          attr: { d: "M75,145 Q90,160 90,350" },
          rotation: 25,
          transformOrigin: "top center",
          ease: "sine.inOut",
          duration: 2.5,
        },
        "<"
      )
      .to(balloonRef.current, { y: "-=0", ease: "sine.inOut", duration: 5 })
      .to(
        stringRef.current,
        {
          attr: { d: "M75,145 Q60,160 60,350" },
          rotation: -25,
          transformOrigin: "top center",
          ease: "sine.inOut",
          duration: 5,
        },
        "<"
      )
      .to(balloonRef.current, { y: "+=15", ease: "sine.inOut", duration: 2.5 })
      .to(
        stringRef.current,
        {
          attr: { d: "M75,145 Q75,160 75,330" },
          rotation: 0,
          transformOrigin: "top center",
          ease: "sine.inOut",
          duration: 2.5,
        },
        "<"
      );

    // Scroll-triggered animation
    const handleScroll = () => {
      const maxScroll = document.body.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      const scrollPercentage = currentScroll / maxScroll;
      containerTl.progress(scrollPercentage);
      balloonTl.progress(scrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <svg
      width="150"
      height="450"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translateX(-50%)",
        overflow: "visible",
        zIndex: "1",
      }}
      ref={containerRef}
    >
      <defs>
        <radialGradient id="pinkGradient" cx="0.5" cy="0.4" r="0.7">
          <stop offset="0%" style={{ stopColor: "#FFAEC8", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#FF69B4", stopOpacity: 1 }}
          />
        </radialGradient>
        <filter id="balloonShadow" x="-20%" y="-20%" width="200%" height="200%">
          <feOffset result="offOut" in="SourceGraphic" dx="10" dy="10" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <g className="balloon" ref={balloonRef} filter="url(#balloonShadow)">
        <ellipse cx="75" cy="75" rx="70" ry="75" fill="url(#pinkGradient)" />
        <path d="M30,35 Q60,15 90,35 T110,85" fill="white" opacity="0.5" />
      </g>
      <path
        id="balloonString"
        d="M75,148 Q75,163 75,330"
        fill="none"
        stroke="black"
        ref={stringRef}
      />
    </svg>
  );
}

export default BalloonComponent;
