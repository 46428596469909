import React, { useState, useEffect } from "react";
import "./topHeader.css";

function TopHeader() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.length > 2) {
      fetch(`https://balonim2001.com/php_files/search_events.php?q=${value}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSearchResults(data.events);
          } else {
            setSearchResults([]);
          }
        })
        .catch((error) => console.error("Error searching events:", error));
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div id="top-header-container">
      <div id="details-container">
        <div className="contact-icon">054-6000775</div>
        <div className="contact-icon">balonim2001@gmail.com</div>
      </div>
      <div id="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="חפש אירועים..."
          style={{
            borderRadius: searchResults.length > 0 ? "25px 25px 0 0" : "25px",
          }}
        />
        <div id="search-results">
          {searchResults.map((event) => (
            <a
              key={event.id}
              href={
                event.type === "business"
                  ? `/אירועים-עסקיים/${encodeURIComponent(event.name)}`
                  : `/אירועים-פרטיים/${encodeURIComponent(event.name)}`
              }
              className="search-result-item"
            >
              <img src={event.icon} alt={event.name} className="event-icon" />
              {event.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
