import React, { useEffect, useState } from "react";
import "./Hero.css";

function Hero() {
  const [typedText, setTypedText] = useState("");
  const fullText = "מכניסים לך צבע לאירוע";
  const typingDelay = 100; // Delay in ms between each character

  useEffect(() => {
    let charIndex = 0;
    const typeChar = () => {
      setTypedText(fullText.slice(0, charIndex));
      charIndex++;
      if (charIndex <= fullText.length) {
        setTimeout(typeChar, typingDelay);
      }
    };

    // Delay the start of typing effect until the title animation is done
    const typingStartDelay = 0; // assuming 0.1s delay per character in title
    const timer = setTimeout(typeChar, typingStartDelay);

    return () => clearTimeout(timer);
  }, []);

  const title = "בלוני אכפת לי".split("").map((char, index) =>
    char !== " " ? (
      <span
        key={index}
        className="hero-char"
        style={{ animationDelay: `${index * 0.1}s` }}
      >
        {char}
      </span>
    ) : (
      <span key={index} className="space">
        {char}
      </span>
    )
  );

  const description = (
    <p className="hero-description" dir="rtl">
      {typedText.split("צבע").reduce((prev, current, index) => (
        <>
          {prev}
          {index > 0 && <span className="color-word">צבע</span>}
          {current}
        </>
      ))}
    </p>
  );

  return (
    <div id="hero-container">
      <div className="hero-overlay"></div>
      <div id="hero-content">
        <h1 className="hero-title">{title}</h1>
        {description}
      </div>
    </div>
  );
}

export default Hero;
