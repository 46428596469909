import React, { useState } from "react";
import "./AddReview.css"; // Make sure the path is correct

function AddReview() {
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [file, setFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("customer_name", name);
    formData.append("review_text", review);
    formData.append("image", file);

    try {
      const response = await fetch(
        "https://balonim2001.com/php_files/add_review.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      alert(data.message);
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };

  return (
    <div className="add-review-container">
      <form onSubmit={handleSubmit} className="review-form">
        <h2 className="form-title"></h2>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="שם"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="ביקורת"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label style={{ textAlign: "right" }}>תמונה(לא חובה)</label>
          <input
            type="file"
            className="form-control"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <button type="submit" className="submit-btn">
          שליחת ביקורת
        </button>
      </form>
    </div>
  );
}

export default AddReview;
