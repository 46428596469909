import React, { useState, useEffect } from "react";
import "./ManageCategories.css";

function ManageCategories() {
  const [categories, setCategories] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({
    id: "",
    name: "",
    description: "",
    type: "business",
    mainCategory: false,
    parentId: "",
    images: [],
    mainImage: "", // Field for the main image URL
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mainImageFile, setMainImageFile] = useState(null); // State for the main image file
  const [filesLeftToUpload, setFilesLeftToUpload] = useState(0);

  useEffect(() => {
    fetch("https://balonim2001.com/php_files/fetch_categories.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const categoriesWithCorrectTypes = data.data.map((category) => ({
            ...category,
            mainCategory: category.mainCategory === "1", // Transform to boolean
          }));
          setCategories(categoriesWithCorrectTypes);
        }
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCurrentCategory((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value, // Directly use checked for checkboxes
    }));
  };

  const handleMainImageChange = (event) => {
    setMainImageFile(event.target.files[0]); // Store the selected main image file
  };

  const editCategory = (category) => {
    setEditing(true);
    fetch(
      `https://balonim2001.com/php_files/fetch_images.php?categoryId=${category.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCurrentCategory({
            ...category,
            images: data.images.filter((img) => !img.is_main), // Exclude the main image from the images array
            mainImage: data.images.find((img) => img.is_main)?.file_path || "", // Set the main image separately
            mainCategory:
              category.mainCategory === 1 || category.mainCategory === true, // Handle both potential true values
          });
        } else {
          alert("Failed to fetch images or no images found");
        }
      });
  };

  const updateCategory = () => {
    const formData = new FormData();
    formData.append("id", currentCategory.id);
    formData.append("name", currentCategory.name);
    formData.append("description", currentCategory.description);
    formData.append("type", currentCategory.type);
    formData.append(
      "mainCategory",
      currentCategory.mainCategory ? "true" : "false"
    );

    if (mainImageFile) {
      formData.append("icon", mainImageFile); // Match the name expected by PHP
    }

    fetch("https://balonim2001.com/php_files/update_category.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setEditing(false);
          fetch("https://balonim2001.com/php_files/fetch_categories.php")
            .then((response) => response.json())
            .then((data) => setCategories(data.data));
        }
      })
      .catch((error) => {
        console.error("Error in fetch operation:", error.message);
      });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setFilesLeftToUpload(files.length);
  };

  const uploadImages = () => {
    if (selectedFiles.length === 0) {
      alert("No files selected!");
      return;
    }

    const totalFiles = selectedFiles.length;
    let uploadedFiles = 0;

    selectedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("categoryId", currentCategory.id);

      fetch("https://balonim2001.com/php_files/add_image.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            uploadedFiles += 1;
            setFilesLeftToUpload(totalFiles - uploadedFiles);
            if (uploadedFiles === totalFiles) {
              alert("כל התמונות הועלו בהצלחה");
              setSelectedFiles([]);
            }
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          alert("Error uploading image");
        });
    });
  };

  const handleDeleteImage = (imageId) => {
    fetch("https://balonim2001.com/php_files/delete_image.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageId }), // Ensure imageId is correctly passed
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Debug information:", data.debug); // Log debug information
        if (data.success) {
          alert("התמונה נמחקה בהצלחה");
          setCurrentCategory((prevState) => ({
            ...prevState,
            images: prevState.images.filter((image) => image.id !== imageId), // Filter out the deleted image
          }));
        } else {
          alert(data.message || "Failed to delete image");
        }
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
        alert("Error deleting image");
      });
  };

  const exitEditing = () => {
    setEditing(false);
    setCurrentCategory({
      id: "",
      name: "",
      description: "",
      type: "business",
      mainCategory: false,
      parentId: "",
      images: [],
      mainImage: "",
    });
    setSelectedFiles([]);
    setMainImageFile(null); // Clear main image file
  };

  return (
    <div className="manage-categories-container">
      {editing ? (
        <div className="edit-container">
          <div className="edit-header">
            <h2>עריכת קטגוריה</h2>
            <button className="exit-button" onClick={exitEditing}>
              X
            </button>
          </div>
          <label className="input-label">שינוי תמונה ראשית</label>
          <input
            type="file"
            name="mainImage"
            className="input-field"
            onChange={handleMainImageChange}
          />
          <label className="input-label">שם קטגוריה</label>
          <input
            className="input-field"
            type="text"
            name="name"
            value={currentCategory.name}
            onChange={handleInputChange}
          />
          <label className="input-label">תיאור קטגוריה</label>
          <textarea
            className="input-textarea"
            name="description"
            value={currentCategory.description}
            onChange={handleInputChange}
          ></textarea>
          <label className="input-label">סוג קטגוריה</label>
          <select
            className="input-select"
            name="type"
            value={currentCategory.type}
            onChange={handleInputChange}
          >
            <option value="business">עסקי</option>
            <option value="private">פרטי</option>
            <option value="other">אחר</option>
          </select>
          <div className="checkbox-container">
            <label className="input-label">קטגוריה ראשית</label>
            <input
              className="input-checkbox"
              type="checkbox"
              name="mainCategory"
              checked={currentCategory.mainCategory}
              onChange={handleInputChange}
            />
          </div>
          <label className="input-label">מספר מזהה של קטגוריית אב</label>
          <input
            className="input-field"
            type="number"
            name="parentId"
            value={currentCategory.parentId}
            onChange={handleInputChange}
            disabled
          />
          <div className="image-container">
            <h3>תמונות קיימות</h3>
            {currentCategory.images.map((image) => (
              <div key={image.id} className="image-preview">
                <img src={image.file_path} alt="Category" />
                <button
                  className="delete-image-btn"
                  onClick={() => handleDeleteImage(image.id)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div id="image-upload-container">
            <h3>העלאת תמונות נוספות</h3>
            <input
              type="file"
              className="input-field"
              onChange={handleFileChange}
              multiple // Enable multiple file selection
            />
            <button className="upload-button" onClick={uploadImages}>
              העלאת תמונות
            </button>
            <p>תמונות שנותרו: {filesLeftToUpload}</p>
          </div>
          <button
            className="update-button"
            onClick={updateCategory}
            disabled={filesLeftToUpload > 0}
          >
            עדכן קטגוריה
          </button>
        </div>
      ) : (
        <div className="category-list">
          {categories.map((category) => (
            <div key={category.id} className="category-item">
              <h4>{category.name}</h4>
              <button
                className="edit-button"
                onClick={() => editCategory(category)}
              >
                ערוך
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ManageCategories;
