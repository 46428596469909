import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./EventPage.css";

function EventPage() {
  const { categoryName } = useParams();
  const [category, setCategory] = useState(null);
  const [galleryImages, setGalleryImages] = useState([[], [], []]);
  const [flatGalleryImages, setFlatGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serverMessage, setServerMessage] = useState(null); // State to store server messages
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === flatGalleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? flatGalleryImages.length - 1 : prevIndex - 1
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name: event.target.name.value,
      phoneNumber: event.target.phoneNumber.value,
      notes: event.target.notes.value,
      imageUrl: flatGalleryImages[selectedImageIndex].file_path,
      eventName: category.name,
    };

    fetch("https://balonim2001.com/php_files/save_user_info.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log to see the actual data structure
        if (data.success) {
          alert("Information submitted successfully.");
          setShowModal(false); // Close the modal on success
        } else {
          throw new Error(data.message || "Unknown error"); // Throw an error if not successful
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setServerMessage(error.message); // Update state with error message
      });
  };

  useEffect(() => {
    const encodedCategoryName = encodeURIComponent(categoryName);
    fetch(
      `https://balonim2001.com/php_files/fetch_category.php?categoryName=${encodedCategoryName}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCategory(data.data);
          fetchGalleryImages(data.data.id);
        } else {
          throw new Error(data.message || "Category not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
        setError("Failed to fetch category details");
        setLoading(false);
      });
  }, [categoryName]);

  const fetchGalleryImages = (categoryId) => {
    fetch(
      `https://balonim2001.com/php_files/fetch_category_gallery_images.php?categoryId=${categoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          distributeImagesToColumns(data.images);
          setFlatGalleryImages(data.images); // Flattened list of all images
        } else {
          throw new Error(data.message || "Failed to fetch gallery images");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching gallery images:", error);
        setError(error.message);
        setLoading(false);
      });
  };

  const distributeImagesToColumns = (images) => {
    const columns = [[], [], []];
    const heights = [0, 0, 0];

    images.forEach((image) => {
      const img = new Image();
      img.src = image.file_path;
      img.onload = () => {
        const smallestColumnIndex = heights.indexOf(Math.min(...heights));
        columns[smallestColumnIndex].push(image);
        heights[smallestColumnIndex] += img.height;
        setGalleryImages([...columns]);
      };
    });
  };

  if (loading) return <div className="loading-message">טוען...</div>;
  if (!category) return <div className="error-message">קטגוריה לא נמצאה</div>;

  return (
    <div className="event-page-container">
      <div
        className="event-header"
        style={{ backgroundImage: `url(${category.icon})` }}
      >
        <h1 className="event-title">{category.name}</h1>
      </div>
      <div className="event-details">
        <p className="event-description">
          {category.description || "No detailed description provided."}
        </p>
        <div className="event-gallery">
          {galleryImages.map((column, columnIndex) => (
            <div key={columnIndex} className="gallery-column">
              {column.map((image, index) => (
                <div
                  key={index}
                  className="gallery-item"
                  onClick={() =>
                    handleImageClick(
                      flatGalleryImages.findIndex((img) => img === image)
                    )
                  }
                >
                  <img
                    src={image.file_path} // Make sure you have a URL or a correct path
                    alt={`Gallery image ${index}`}
                    className="gallery-image"
                  />
                  <div className="image-info">
                    <h4>{image.title}</h4>
                    <button onClick={() => handleImageClick(image.file_path)}>
                      אני רוצה כזה!
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <div className="image-modal">
          <span
            className="image-modal-close"
            onClick={() => setShowModal(false)}
          >
            &times;
          </span>
          <button className="image-modal-prev" onClick={handlePrevImage}>
            &#10094;
          </button>
          <img
            src={flatGalleryImages[selectedImageIndex].file_path}
            alt="Selected"
            className="image-modal-content"
          />
          <button className="image-modal-next" onClick={handleNextImage}>
            &#10095;
          </button>
          <div className="image-modal-caption">
            {flatGalleryImages[selectedImageIndex].title}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventPage;
